<template>
  <div id="influence-wrapper">
    <div
      class="influence clearfix"
      v-for="(item, index) in influenceList"
      :key="index"
    >
      <div class="date-wrapper">
        <div class="dot">
          <div></div>
        </div>
        <div class="date">{{ item.date }}</div>
      </div>
      <div class="right">
        <!-- 新闻标题以及 详情按钮 -->
        <div class="title-wrapper" v-if="item.title">
          <div class="news-title" v-if="item.title">
            {{ item.title }}
          </div>
          <div class="news-details" v-if="item.details">
            <a :href="item.details">点击查看详情</a>
          </div>
        </div>
        <!-- 新闻内容 -->
        <div class="news-content" v-if="item.content">
          <p v-for="(p, pindex) in item.content" :key="pindex">
            {{ p }}
          </p>
        </div>
        <!-- 新闻图片 -->
        <div class="news-photos" v-if="item.photos">
          <div
            class="photo"
            v-for="(photo, photoIndex) in item.photos"
            :key="photoIndex"
          >
            <div class="img">
              <img :src="photo.img" alt="" />
            </div>
            <div class="captions">
              {{ photo.captions }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SocialInfluence",
  data() {
    return {
      influenceList: [
        {
          date: "2019.10",
          title: "",
          details: "",
          content: [
            "2019年，长益基金会获得了获得了新华社、民政部官微、半月谈、人民网、和人民日报等主流媒体的报道和肯定，同时获得了腾讯公益头条、腾讯NOW直播、长江校友官微等公益传播，获得政府、公益行业、公众的较大关注。",
            "2019年，长益基金会获得了获得了新华社、民政部官微、半月谈、人民网、和人民日报等主流媒体的报道和肯定，同时获得了腾讯公益头条、腾讯NOW直播、长江校友官微等公益传播，获得政府、公益行业、公众的较大关注。"
          ],
          photos: [
            {
              img: require("@/assets/images/home-new4.jpg"),
              captions:
                "施甸县“关怀深山留守老人”项目的陪伴人员李立平给李正相老人剪头发"
            },
            {
              img: require("@/assets/images/home-new5.jpg"),
              captions:
                "施甸县“关怀深山留守老人”项目的陪伴人员李立平给李正相老人剪头发"
            }
          ]
        },
        {
          date: "2019.02",
          title: "新华社：“李立平的心愿：让深山独居老人不再孤单”",
          details:
            "http://www.xinhuanet.com/english/2019-02/09/c_137809319.htm?from=groupmessage&isappinstalled=0",
          content: [
            "2019年，长益基金会获得了获得了新华社、民政部官微、半月谈、人民网、和人民日报等主流媒体的报道和肯定，同时获得了腾讯公益头条、腾讯NOW直播、长江校友官微等公益传播，获得政府、公益行业、公众的较大关注。",
            "2019年，长益基金会获得了获得了新华社、民政部官微、半月谈、人民网、和人民日报等主流媒体的报道和肯定，同时获得了腾讯公益头条、腾讯NOW直播、长江校友官微等公益传播，获得政府、公益行业、公众的较大关注。"
          ],
          photos: [
            {
              img: require("@/assets/images/influence1.jpg"),
              captions:
                "施甸县“关怀深山留守老人”项目的陪伴人员李立平给李正相老人剪头发"
            },
            {
              img: require("@/assets/images/influence3.jpg"),
              captions:
                "施甸县“关怀深山留守老人”项目的陪伴人员李立平给李正相老人剪头发"
            }
          ]
        },
        {
          date: "2019.02",
          title: "新华社：“李立平的心愿：让深山独居老人不再孤单”",
          details:
            "http://www.xinhuanet.com/english/2019-02/09/c_137809319.htm?from=groupmessage&isappinstalled=0",
          content: [
            "2019年，长益基金会获得了获得了新华社、民政部官微、半月谈、人民网、和人民日报等主流媒体的报道和肯定，同时获得了腾讯公益头条、腾讯NOW直播、长江校友官微等公益传播，获得政府、公益行业、公众的较大关注。",
            "2019年，长益基金会获得了获得了新华社、民政部官微、半月谈、人民网、和人民日报等主流媒体的报道和肯定，同时获得了腾讯公益头条、腾讯NOW直播、长江校友官微等公益传播，获得政府、公益行业、公众的较大关注。"
          ],
          photos: []
        },
        {
          date: "2019.02",
          title: "新华社：“李立平的心愿：让深山独居老人不再孤单”",
          details: "",
          content: [],
          photos: [
            {
              img: require("@/assets/images/influence2.jpg"),
              captions:
                "施甸县“关怀深山留守老人”项目的陪伴人员李立平给李正相老人剪头发"
            }
          ]
        }
      ]
    };
  }
};
</script>

<style lang="scss" scoped>
.clearfix::after {
  content: "";
  display: block;
  clear: both;
}
#influence-wrapper {
  margin: 0 auto;
  width: 1110px;
  border-left: 1px solid #ccc;
  color: #004898;
  padding: 100px 0 0 0;
}
.influence {
  width: 100%;
  position: relative;
  margin-bottom: 90px;
}
.date-wrapper {
  width: 160px;
  height: 34px;
  left: -17px;
  position: absolute;
  display: flex;
  justify-content: space-between;
  .dot {
    display: inline-block;
    width: 34px;
    height: 34px;
    border-radius: 17px;
    background-color: #d0dfeb;
    display: flex;
    justify-content: center;
    align-items: center;
    > div {
      width: 18px;
      height: 18px;
      border-radius: 9px;
      background-color: #004898;
    }
  }
  .date {
    line-height: 34px;
    font-size: 24px;
    font-family: "Impact";
  }
}
.right {
  float: right;
  width: 910px;
  // height: 500px;
  padding: 50px;
  background-color: #f5f5f5;
  .title-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 50px;
    .news-title {
      font-size: 20px;
    }
    .news-details {
      text-align: center;
      cursor: pointer;
      font-size: 14px;
      width: 168px;
      height: 34px;
      line-height: 34px;
      background-color: #004898;
      border-radius: 17px;
      &:hover {
        background-color: #1159ac;
      }
      a {
        color: #fff;
        text-decoration: none;
      }
    }
  }
  .news-content {
    text-align: start;
    margin-bottom: 50px;
    p {
      margin-bottom: 40px;
    }
  }
  .news-photos {
    display: flex;
    justify-content: space-between;
    padding: 0 10px;
    .photo {
      width: 386px;
      .img {
        width: 100%;
        height: 240px;
        overflow: hidden;
        img {
          width: 100%;
        }
      }
      .captions {
        margin-top: 25px;
        padding: 0 4px;
        text-align: start;
        font-size: 14px;
        color: #999;
        line-height: 30px;
      }
    }
  }
}
</style>
